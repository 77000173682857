@import "../../../../resources/variables.scss";
.program-used {
  .programs {
    display: flex;
    flex-flow: row;
    p {
      font-size: 35px;
    }
  }
}
@media (max-width: 957px) {
  .program-used {
    .programs {
      display: flex;
      flex-flow: row;
      justify-content: center;
      p {
        font-size: 35px;
      }
    }
  }
}