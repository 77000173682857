@import "../../../resources/variables.scss";
.work {
  padding: 80px;
  .work-box {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 40px 20%;
    text-align: left;
    .right {
      text-align: right;
    }
  }
}
@media (max-width: 957px) {
  .work {
    padding: 80px 0;
    .work-box {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      margin: 40px 20%;
      text-align: left;
      .right {
        text-align: right;
      }
    }
  }
}
