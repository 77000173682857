@import "../../../resources/variables.scss";
.education {
  background-color: $color-background-2;
  padding: 80px;
  .edu-box {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 20px 20%;
    text-align: left;
    .right {
      text-align: right;
    }
  }
}
@media (max-width: 957px) {
  .education {
    background-color: $color-background-2;
    padding: 80px 5px;
    .edu-box {
      width: 90%;
      display: flex;
      flex-flow: column;
      margin: 20px 10px;
      text-align: center;
      .right {
        text-align: center;
      }
    }
  }
}
