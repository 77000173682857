@import "../../resources/variables.scss";
.projects {
  .header {
    margin: 40px;
  }
  .projectBoxes {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
  }
}
