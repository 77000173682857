@import "../../resources/variables.scss";
.resume {
  background-color: white; /*linear-gradient(
    $color-background,
    rgb(53, 59, 65),
    $color-background
  );*/
  padding: 0;
  .top {
    padding: 30px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/portfolio-2e0f3.appspot.com/o/photos1.jpg?alt=media&token=2d29534a-c082-458e-b9c2-1997841c5760");
    background-size: cover;

    img {
      margin-top: 80px;
      width: 300px;
      height: 300px;
      object-fit: cover;
      box-shadow: $box-shadow;
      border-radius: 50%;
    }
    h1 {
      color: white;
      margin: 20px;
      float: center;
    }
    h4 {
      color: white;
      margin-bottom: 80px;
    }
  }
  .downloadRes {
    margin: 4px 0;
    padding: 50px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .download-button {
      background-color: $color-button-background;
      border-radius: $button-border-size;
      text-align: center;
      width: $button-width;
      padding: 5px;
      margin: 5px;

      border: 3px solid $color-button-border;
      p {
        color: $color-button-text;
      }
      &:hover {
        border: 3px solid$color-button-border-hoover;
        background-color: $color-button-background-hoover;
        p {
          color: $color-button-text-hoover;
        }
      }
    }
  }
}
@media (max-width: 957px) {
  .resume {
    background-color: white; /*linear-gradient(
      $color-background,
      rgb(53, 59, 65),
      $color-background
    );*/
    padding: 0;
    .top {
      padding: 30px 0px;
      background-image: url("https://firebasestorage.googleapis.com/v0/b/portfolio-2e0f3.appspot.com/o/photos1.jpg?alt=media&token=2d29534a-c082-458e-b9c2-1997841c5760");
      background-size: cover;

      img {
        margin-top: 80px;
        width: 200px;
        height: 200px;
        object-fit: cover;
        box-shadow: $box-shadow;
        border-radius: 50%;
      }
      .top-text {
       margin:0 10px;
        h1 {
          color: white;
          margin: 0px;
          float: center;
          font-size: 20px;
        }
        h4 {
          color: white;

          margin-bottom: 80px;
        }
      }
    }
    .downloadRes {
      margin: 4px 0;
      padding: 10px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      .download-button {
        background-color: $color-button-background;
        border-radius: $button-border-size;
        text-align: center;
        width: $button-width;
        padding: 5px;
        margin: 5px;

        border: 3px solid $color-button-border;
        p {
          color: $color-button-text;
        }
        &:hover {
          border: 3px solid$color-button-border-hoover;
          background-color: $color-button-background-hoover;
          p {
            color: $color-button-text-hoover;
          }
        }
      }
    }
  }
}
