@import "../../../resources/variables.scss";

.programing-skills {
  padding: 80px;
  h2 {
    text-align: center;
  }
  .skills {
    margin: 40px;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
    .type {
      width: 130px;
    }
  }
}
