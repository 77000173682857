@import "../../../resources/variables.scss";

.project-box {
  display: flex;
  flex-direction: column;
  background-color: $color-project-box;
  height: 300px;
  width: 300px;
  justify-content: center;
  border-radius: 20px;
  margin: 20px 0;
  box-shadow: $box-shadow;
  h2 {
    color: $color-project-box-text;
  }
  img {
    max-width: 85%;
    height: auto;
    margin: 0 auto;
  }

  &:hover {
    background-color: $color-project-box-hoover;

    h2 {
      color: $color-project-box-text-hoover;
    }
  }

  
}
@media only screen and (max-width: $target-width) {
  .project-box {
    display: flex;
    flex-direction: column;
    background-color: $color-project-box;
    height: 300px;
    width: 300px;
    justify-content: center;
    border-radius: 20px;
    margin: 20px 0;
    box-shadow: $box-shadow;
    h2 {
      color: $color-project-box-text;
    }
    img {
      max-width: 85%;
      height: auto;
      margin: 0 auto;
    }
  
    &:hover {
      background-color: $color-project-box-hoover;
  
      h2 {
        color: $color-project-box-text-hoover;
      }
    }
  
    
  }
}