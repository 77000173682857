@import "../../../resources/variables.scss";

.project-site {
  .top-ps {
    margin: 80px 0px;
    height: 1000px;

    .title {
      height: 40%;
      width: 100%;
      .background {
        height: 100%;
        width: 100%;
        object-fit: cover;
        display: block;
        filter: blur(5px);
        z-index: -1;
      }
      .logo {
        position: absolute;
        top: 220px;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 25%;
        width: auto;
        background-color: none;
        z-index: 2;
      }
      h1 {
        position: absolute;
        top: 370px;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 5vh;
        color: $color-name;

        z-index: 2;
      }
      h3 {
        position: absolute;
        top: 410px;
        left: 50%;
        font-size: 2vh;
        transform: translate(-50%, -50%);
        color: $color-name;
        z-index: 2;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      margin: 40px 80px;
      align-items: center;
      justify-content: center;

      .about {
        display: flex;
        flex-flow: row;

        img {
          width: 500px;
          margin: 40px;
          box-shadow: $box-shadow;
          border-radius: 5px;
        }
        .about-text {
          text-align: left;
          width: 500px;
          padding: 10px;
          margin: 40px;
        }
      }
      .link-button {
        margin: 5px;
        background-color: $color-button-background;
        border-radius: $button-border-size;
        border: solid $color-button-border;
        text-align: center;
        width: $button-width;
        padding: 5px;
        margin: 5px;

        &:hover {
          background-color: $color-button-background-hoover;
          p {
            color: $color-button-text-hoover;
          }
        }
      }
    }
  }
  .second-part {
    .image-sp {
      background-color: $color-background-2;
      margin: 0 0 10px 0;
      padding: 40px 0;
      display: flex;
      flex-flow: row;
      justify-content: center;

      img {
        border-radius: 5px;
        box-shadow: $box-shadow;
        height: 350px;
      }
      .image-sp-text {
        width: 450px;
        padding: 10px;
      }
    }
    .image-sp-2 {
      margin: 0 0 10px 0;
      padding: 40px 0;
      display: flex;
      flex-flow: row;
      justify-content: center;

      img {
        height: 350px;
        border-radius: 5px;
      }
      .image-sp-text-2 {
        width: 450px;
        padding: 10px;
      }
    }
  }
}
@media (max-width: 957px) {
  .project-site {
    .top-ps {
      margin: 80px 0px 0px 0px;
      height: 1100px;
      .title {
        height: 35%;
        width: 100%;
        .background {
          height: 400px;
          width: 100%;
          object-fit: cover;
          display: block;
          filter: blur(5px);
          z-index: -1;
        }
        .logo {
          position: absolute;
          top: 220px;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 25%;
          width: auto;
          background-color: none;
          z-index: 2;
        }
        .logo-text {
          width: 100%;
          h1 {
            position: absolute;
            top: 330px;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 4vh;
            color: $color-name;
            width: 100%;
            z-index: 2;
          }
          h3 {
            position: absolute;
            top: 370px;
            left: 50%;
            font-size: 2.5vh;
            transform: translate(-50%, -50%);
            color: $color-name;
            width: 90%;

            z-index: 2;
          }
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        margin: 20px 0px;
        align-items: center;
        justify-content: center;

        .about {
          display: flex;
          flex-flow: column;

          img {
            width: 100%;
            margin: 10px 0px;
            box-shadow: $box-shadow;
            border-radius: 5px;
          }
          .about-text {
            text-align: center;
            width: 90%;
            padding: 10px;
            margin: 10px;
          }
        }

        .link-button {
          margin: 15px 0;
          background-color: $color-button-background;
          border-radius: $button-border-size;
          border: solid $color-button-border;
          text-align: center;
          width: $button-width;
          padding: 5px;
          margin: 5px;

          &:hover {
            background-color: $color-button-background-hoover;
            p {
              color: $color-button-text-hoover;
            }
          }
        }
      }
    }
    .second-part {
      margin: 40px 0;
      .image-sp {
        background-color: $color-background-2;
        margin: 10px 0;
        padding: 20px 0;
        display: flex;
        flex-flow: column-reverse;
        justify-content: center;

        img {
          border-radius: 5px;
          box-shadow: $box-shadow;
          width: 100%;
          height: 100%;
        }
        .image-sp-text {
          width: 90%;
          padding: 10px;
        }
      }
      .image-sp-2 {
        margin: 0 0 10px 0;
        padding: 40px 0;
        display: flex;
        flex-flow: column;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
        .image-sp-text-2 {
          width: 90%;
          padding: 10px;
        }
      }
    }
  }
}
