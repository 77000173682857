@import "../../../resources/variables.scss";
.design-skills {
  padding: 80px;
  background-color: $color-background-2;
  .design {
    display: flex;
    flex-flow: row wrap;
    margin: 40px;
    justify-content: center;

    .design-type {
      width: 130px;
    }
  }
}

@media (max-width: 957px) {
  .design-skills {
    padding: 80px 0;

    .design {
      margin: 40px 0;
      justify-content: center;
      .design-type {
        width: 130px;
      }
    }
  }
}
