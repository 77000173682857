@import "../../resources/variables.scss";
.first-section {
  display: flex;
  flex-flow: column;
  justify-content: center;
  .name {
    font-size: 5rem;
    color: $color-name;
  }
  .skills {
    font-size: 1.2rem;
    color: $color-name;
  }
  background: linear-gradient(-45deg, #002f4f, #002f4f, #a0895d, #fadc17);
  background-size: 400% 400%;
  animation: gradient 25s ease infinite;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
      //border-radius:0% 0%  70% 70%;
    }
    50% {
      background-position: 100% 50%;
      //border-radius: 0% 0%  0% 0%;
    }
    100% {
      background-position: 0% 50%;
      // border-radius: 0% 0%  70% 70%;
    }
  }
}
.short-info-box {
  display: flex;
  flex-flow: row;
  justify-content: center;

  .short-info {
    text-align: left;
    display: flex;
    flex-flow: column;
    justify-content: center;

    p {
      padding: 10px;
      width: 500px;
    }
  }
  .image-container {
    margin: 80px 0;
    display: grid;
    position: relative; // imperative for the overlapping to work
    grid-template-columns: repeat(8, 1fr);
    .bot-image {
      grid-column: 4 / -1;
      grid-row: 1;
      img {
        width: 280px;
        height: 400px;
        object-fit: cover;
        box-shadow: $box-shadow;
      }
    }
    .top-image {
      grid-row: 1;
      grid-column: 1 / span 5;
      padding-top: 20%; // slightly arbitrary, keeps proportion once resized
      z-index: 1; // tells the browser to make this image on top
      img {
        width: 270px;
        height: 300px;

        object-fit: cover;
        box-shadow: $box-shadow;
      }
    }
  }
}
@media (max-width: 957px) {
  .first-section {
    height: 750px;
    padding: 10px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .name {
      text-align: center;
      font-size: 2.5rem;
      color: $color-name;
    }
    .skills {
      text-align: center;
      font-size: 0.9rem;
      color: $color-name;
    }
  }
  .short-info-box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;

    .short-info {
      padding: 20px;
      text-align: center;
      display: flex;
      flex-flow: column;
      align-content: center;
      justify-content: center;
      h1 {
        padding: 10px;
      }
      p {
        padding: 20px;
        width: 90%;
      }
    }
    .image-container {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      .bot-image {
        display: none;
        img {
          width: 100px;
          height: 200px;
          object-fit: cover;
          box-shadow: $box-shadow;
        }
      }
      .top-image {
        padding: 0;
        margin: 0;
        img {
          width: 100%;
          height: 300px;

          object-fit: cover;
          box-shadow: $box-shadow;
        }
      }
    }
  }
}
