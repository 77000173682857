@import "../../resources/variables.scss";
.about {
  margin: 0;
  padding: 0;
  .header {
    margin: 80px 80px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 80px;

    .text {
      margin: 0 20px;
      h2 {
        text-align: left;
      }
      p {
        padding: 10px;
        width: 460px;
        text-align: left;
      }
    }
    img {
      height: 400px;
      object-fit: cover;
    }
  }
}
@media (max-width: 957px) {
  .about {
    margin: 0;
    padding: 0;
    .header {
      margin: 0px 0px;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      padding: 80px 0px;

      .text {
        margin: 5px 20px;
        h2 {
          text-align: center;
        }
        p {
          padding: 0px;
          width: 100%;
          text-align: center;
        }
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
