@import "../../resources/variables.scss";

$navigation-height: 80px;
$target-width: 640px;

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: $navigation-height;
  background-color: $color-navigation;
  z-index: 10000;

  .logo {
    display: flex;
    flex-flow: row;

    img {
      height: $navigation-height - 25px;
    }
    .text {
      color: $color-navigation-text;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-start;
      h4 {
        margin: 2px;
      }
    }
  }

  .menu {
    a {
      display: inline-block;
      line-height: $navigation-height;
      padding: 0 10px;
      font-size: 1rem;
      color: $color-navigation-text;

      @media only screen and (max-width: $target-width) {
        padding: 0 5px;
      }
      &:hover {
        color: $color-underline;
      }
    }
    .active {
      color: $color-active;
      font-weight: bold;
      text-decoration: underline $color-underline;
    }
  }
  .icons {
    @media only screen and (max-width: $target-width) {
      display: none;
    }
    a {
      margin: 0 5px;
      color: $color-navigation-text;
      display: inline-block;
      &:hover {
        color: $color-underline;
      }
    }
  }
}
@media (max-width: 957px) {
  .navigation {
    .logo {
      display: flex;
      flex-flow: row;

      img {
        height: $navigation-height - 25px;
      }
      .text {
        display: none;
        h4 {
          margin: 2px;
        }
      }
    }
  }
}
